import React from "react";
import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";
import calendar from "@icons/calendar.svg";
import Icon from "../icon";
interface BDatePickerProps {
  value: DateValueType | null;
  onChange: (newDate: DateValueType | null) => void;
  label?: string;
  required?: boolean;
  className?: string;
  placeholder: string;
}

const BDatePicker: React.FC<BDatePickerProps> = ({
  value,
  placeholder,
  onChange,
  required = false,
  className = "",
}) => {
  const handleChange = (selectedDate: DateValueType | null) => {
    onChange(selectedDate);
  };

  return (
    <div className={`relative flex flex-col mb-4 ${className}`}>
      <Datepicker
        placeholder={placeholder}
        useRange={false}
        asSingle
        onChange={handleChange}
        toggleIcon={() => (
          <Icon
            iconName="calendar"
            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-600 pointer-events-none"
          />
        )}
        value={value}
        required={required}
        inputClassName="border text-gray-700 text-sm h-11 w-full bg-gray-100 border-gray-400 rounded-md px-4 py-2 pr-10 focus:outline-none focus:ring-2 focus:ring-indigo-400"
      />
    </div>
  );
};

export default BDatePicker;
