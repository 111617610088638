import { ReactElement } from "react";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import logo from "../../assets/images/logo.png";
import BreadCrumb from "./BreadCrumb";
import UserProfile from "./UserProfile";
import LanguageSelect from "./LanguageSelect";
import { useTranslate } from "../../utils/locales/use-locales";
import Icon from "@components/core-components/icon";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function DashboardLayout({ children }: any) {
  const { t } = useTranslate();

  interface NavigationItem {
    name: string;
    href?: string;
    icon: ReactElement;
    current: boolean;
    children?: { name: string; href?: string }[];
  }

  const navigation: NavigationItem[] = [
    {
      name: t("menuItems.dashboard"),
      href: "#",
      icon: <Icon iconName="categoryIcon2" className="h-6 w-6 shrink-0" />,
      current: true,
    },
    {
      name: t("menuItems.players"),
      icon: <Icon iconName="players" className="h-6 w-6 shrink-0" />,
      current: false,
      children: [{ name: t("menuItems.engineering"), href: "#" }],
    },
    {
      name: t("menuItems.reports"),
      icon: <Icon iconName="report" className="h-6 w-6 shrink-0" />,
      current: false,
    },
    {
      name: t("menuItems.payment"),
      href: "#",
      icon: <Icon iconName="payment" className="h-6 w-6 shrink-0" />,
      current: false,
    },
    {
      name: t("menuItems.promotionalTools"),
      href: "#",
      icon: <Icon iconName="promotion" className="h-6 w-6 shrink-0" />,
      current: false,
    },
    {
      name: t("menuItems.sportsbook"),
      href: "#",
      icon: <Icon iconName="bookOpen" className="h-6 w-6 shrink-0" />,
      current: false,
    },
    {
      name: t("menuItems.userManagements"),
      href: "#",
      icon: <Icon iconName="userPen" className="h-6 w-6 shrink-0" />,
      current: false,
    },
    {
      name: t("menuItems.tools"),
      href: "#",
      icon: <Icon iconName="settings" className="h-6 w-6 shrink-0" />,
      current: false,
    },
  ];

  return (
    <>
      <div className="flex bg-gray-200 min-h-screen">
        <div className="fixed w-[253px] flex flex-col h-screen w-1/6 bg-gray-900 pl-4 pr-4 pt-5 overflow-y-auto">
          <div>
            <img src={logo} style={{ width: "161px", height: "39px" }} />
          </div>

          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 space-y-1">
                  {navigation.map((item) => (
                    <li key={item.name}>
                      {!item.children ? (
                        <a
                          href={item.href}
                          className={classNames(
                            "flex flex-row items-center group gap-x-3 rounded-md p-2 text-sm font-normal leading-6 text-gray-400 hover:text-white hover:bg-primary"
                          )}
                        >
                          {item.icon}
                          {item.name}
                        </a>
                      ) : (
                        <Disclosure as="div">
                          <DisclosureButton
                            className={classNames(
                              "flex flex-row items-center group w-full gap-x-3 rounded-md p-2 text-left text-sm font-normal leading-6 text-gray-400 hover:text-white hover:bg-primary"
                            )}
                          >
                            {item.icon}
                            {item.name}
                            <Icon
                              iconName="rightArrow"
                              svgProps={{ width: 18, height: 18 }}
                              className="ml-auto h-5 w-5 shrink-0 only:group-data-[open]:rotate-90 group-data-[open]:text-gray-500"
                            />
                          </DisclosureButton>
                          <DisclosurePanel as="ul" className="mt-1 px-2">
                            {item.children.map((subItem) => (
                              <li key={subItem.name}>
                                <DisclosureButton
                                  as="a"
                                  href={subItem.href}
                                  className={classNames(
                                    "block rounded-md py-2 pl-9 pr-2 text-sm leading-6 text-gray-400 hover:text-white font-normal"
                                  )}
                                >
                                  {subItem.name}
                                </DisclosureButton>
                              </li>
                            ))}
                          </DisclosurePanel>
                        </Disclosure>
                      )}
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </nav>
        </div>

        <div className="flex pl-[253px] flex-grow flex-col w-5/6">
          <div className="box-border h-16 flex items-center bg-white pl-10 justify-between">
            <BreadCrumb />
            <div className="flex items-center">
              <LanguageSelect />
              <UserProfile />
            </div>
          </div>

          <div className="flex-grow overflow-auto pl-10 pr-10 py-8">
            {children}
          </div>
        </div>
      </div>
    </>
  );
}
