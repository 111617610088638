import Icon from "@components/core-components/icon";

function BreadCrumb() {
  const pages = [
    { name: "Players", href: "#", current: false },
    { name: "List", href: "#", current: true },
  ];

  return (
    <>
      <nav aria-label="Breadcrumb" className="flex">
        <ol role="list" className="flex items-center space-x-4">
          <li>
            <div>
              <a href="#" className="text-gray-400 hover:text-gray-500">
                <Icon iconName="house" className="h-5 w-5 flex-shrink-0" />
                <span className="sr-only">Home</span>
              </a>
            </div>
          </li>
          {pages.map((page) => (
            <li key={page.name}>
              <div className="flex items-center">
                <Icon
                  iconName="rightArrow"
                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                  svgProps={{ width: 12, height: 12 }}
                />
                <a
                  href={page.href}
                  aria-current={page.current ? "page" : undefined}
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                >
                  {page.name}
                </a>
              </div>
            </li>
          ))}
        </ol>
      </nav>
    </>
  );
}

export default BreadCrumb;
