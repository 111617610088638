import React, { useState } from "react";
import PInput from "@components/core-components/input";
import BSelect from "@components/core-components/select";
import BDatePicker from "@components/core-components/datePicker";
import { DateValueType } from "react-tailwindcss-datepicker";
import Icon from "@components/core-components/icon";

function GridFilter() {
  const [selectedOption, setSelectedOption] = useState<string>("option1");
  const [selectedOption2, setSelectedOption2] = useState<string>("option1");
  const [selectedOption3, setSelectedOption3] = useState<string>("option1");
  const [registrationDate, setRegistrationDate] =
    useState<DateValueType | null>(null);
  const [lastLoginDate, setLastLoginDate] = useState<DateValueType | null>(
    null
  );
  const [birthdayDate, setBirthdayDate] = useState<DateValueType | null>(null);
  const [firstDepositDate, setFirstDepositDate] =
    useState<DateValueType | null>(null);
  const [verificationDate, setVerificationDate] =
    useState<DateValueType | null>(null);

  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  const regionOptions = [
    { value: "1", label: "Africa" },
    { value: "2", label: "Asia" },
    { value: "3", label: "Central America" },
  ];

  const currencyOptions = [
    { value: "1", label: "USD" },
    { value: "2", label: "EUR" },
    { value: "3", label: "GBP" },
  ];

  const genderOptions = [
    { value: "1", label: "Female" },
    { value: "2", label: "Male" },
  ];

  const verifiedOptions = [
    { value: "1", label: "Yes" },
    { value: "2", label: "No" },
  ];

  const selfExcludedOptions = [
    { value: "1", label: "Yes" },
    { value: "2", label: "No" },
  ];

  const cashdeskOptions = [
    { value: "1", label: "Option A" },
    { value: "2", label: "Option B" },
  ];

  const statusOptions = [
    { value: "1", label: "Active" },
    { value: "2", label: "Inactive" },
  ];

  const smsSubscribedOptions = [
    { value: "1", label: "Yes" },
    { value: "2", label: "No" },
  ];

  const emailSubscribedOptions = [
    { value: "1", label: "Yes" },
    { value: "2", label: "No" },
  ];

  const clientTypeOptions = [
    { value: "1", label: "VIP" },
    { value: "2", label: "Regular" },
  ];

  const stateOptions = [
    { value: "1", label: "California" },
    { value: "2", label: "New York" },
  ];

  const amlStatusOptions = [
    { value: "1", label: "Compliant" },
    { value: "2", label: "Non-Compliant" },
  ];

  return (
    <>
      <div className="grid grid-cols-5 gap-4 mb-4">
        <PInput
          placeholder="Player ID"
          value=""
          onChange={() => {}}
          className="w-full h-12"
        />
        <PInput
          placeholder="EMail"
          value=""
          onChange={() => {}}
          className="w-full h-12"
        />
        <BSelect
          options={regionOptions}
          value={selectedOption}
          onChange={(e: any) => setSelectedOption(e.target.value)}
          className="w-full"
        />
        <BSelect
          options={currencyOptions}
          value={selectedOption2}
          onChange={(e: any) => setSelectedOption2(e.target.value)}
          className="w-full"
        />
        <BSelect
          options={genderOptions}
          value={selectedOption3}
          onChange={(e: any) => setSelectedOption3(e.target.value)}
          className="w-full"
        />
      </div>
      <div className="grid grid-cols-5 gap-4">
        <BDatePicker
          placeholder="Registration Date"
          value={registrationDate}
          onChange={(newDate: DateValueType | null) =>
            setRegistrationDate(newDate)
          }
          className="w-full"
        />
        <BDatePicker
          placeholder="Last Login Date"
          value={lastLoginDate}
          onChange={(newDate: DateValueType | null) =>
            setLastLoginDate(newDate)
          }
          className="w-full"
        />
        <BDatePicker
          value={birthdayDate}
          placeholder="Birthday"
          onChange={(newDate: DateValueType | null) => setBirthdayDate(newDate)}
          className="w-full"
        />
        <BDatePicker
          placeholder="First Deposit Date"
          value={firstDepositDate}
          onChange={(newDate: DateValueType | null) =>
            setFirstDepositDate(newDate)
          }
          className="w-full"
        />
        <BDatePicker
          placeholder="Verification Date"
          value={verificationDate}
          onChange={(newDate: DateValueType | null) =>
            setVerificationDate(newDate)
          }
          className="w-full"
        />
      </div>
      {!showAdvancedFilters && (
        <>
          {" "}
          <div
            className="mt-4 flex items-center justify-center bg-gray-200 text-gray-600 py-2 px-4 rounded cursor-pointer"
            onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
          >
            <Icon
              iconName="plus"
              className="mr-2 flex items-center justify-center mt-1 "
            />
            <span>Show Advanced Filters</span>
          </div>
        </>
      )}

      {showAdvancedFilters && (
        <>
          <div className="grid grid-cols-5 gap-4 mt-4">
            <BSelect
              options={verifiedOptions}
              value={selectedOption}
              onChange={(e: any) => setSelectedOption(e.target.value)}
              className="w-full"
            />
            <BSelect
              options={selfExcludedOptions}
              value={selectedOption2}
              onChange={(e: any) => setSelectedOption2(e.target.value)}
              className="w-full"
            />
            <BSelect
              options={cashdeskOptions}
              value={selectedOption3}
              onChange={(e: any) => setSelectedOption3(e.target.value)}
              className="w-full"
            />
            <PInput
              placeholder="Betshop Group"
              value=""
              onChange={() => {}}
              className="w-full h-12"
            />
            <PInput
              placeholder="Affiliate ID"
              value=""
              onChange={() => {}}
              className="w-full h-12"
            />
            <PInput
              placeholder="BTag"
              value=""
              onChange={() => {}}
              className="w-full h-12"
            />
            <PInput
              placeholder="Min Loyalty Point Balance"
              value=""
              onChange={() => {}}
              className="w-full h-12"
            />
            <PInput
              placeholder="Max Loyalty Point Balance"
              value=""
              onChange={() => {}}
              className="w-full"
            />
            <BSelect
              options={statusOptions}
              value={selectedOption}
              onChange={(e: any) => setSelectedOption(e.target.value)}
              className="w-full"
            />
            <BSelect
              options={emailSubscribedOptions}
              value={selectedOption2}
              onChange={(e: any) => setSelectedOption2(e.target.value)}
              className="w-full"
            />
            <BSelect
              options={smsSubscribedOptions}
              value={selectedOption3}
              onChange={(e: any) => setSelectedOption3(e.target.value)}
              className="w-full"
            />
            <BSelect
              options={clientTypeOptions}
              value={selectedOption}
              onChange={(e: any) => setSelectedOption(e.target.value)}
              className="w-full"
            />
            <BSelect
              options={amlStatusOptions}
              value={selectedOption2}
              onChange={(e: any) => setSelectedOption2(e.target.value)}
              className="w-full"
            />
            <PInput
              placeholder="Mobile"
              value=""
              onChange={() => {}}
              className="w-full h-12"
            />
            <PInput
              placeholder="Zip-Code"
              value=""
              onChange={() => {}}
              className="w-full h-12"
            />
            <PInput
              placeholder="City"
              value=""
              onChange={() => {}}
              className="w-full h-12"
            />
            <PInput
              placeholder="IP Address"
              value=""
              onChange={() => {}}
              className="w-full h-12"
            />
            <PInput
              placeholder="Min Balance"
              value=""
              onChange={() => {}}
              className="w-full h-12"
            />
            <PInput
              placeholder="Max Balance"
              value=""
              onChange={() => {}}
              className="w-full h-12"
            />
            <PInput
              placeholder="Sport Profitability From"
              value=""
              onChange={() => {}}
              className="w-full h-12"
            />
            <PInput
              placeholder="Sport Profitability To"
              value=""
              onChange={() => {}}
              className="w-full h-12"
            />
            <PInput
              placeholder="Casino Profitability From"
              value=""
              onChange={() => {}}
              className="w-full h-12"
            />
            <PInput
              placeholder="Casino Profitability To"
              value=""
              onChange={() => {}}
              className="w-full h-12"
            />
            <PInput
              placeholder="Wrong Login Attempts From"
              value=""
              onChange={() => {}}
              className="w-full h-12"
            />
            <PInput
              placeholder="Wrong Login Attempts To"
              value=""
              onChange={() => {}}
              className="w-full h-12"
            />
          </div>

          <div
            className="mt-4 flex items-center justify-center bg-gray-200 text-gray-600 py-2 px-4 rounded cursor-pointer"
            onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
          >
            <Icon
              iconName="minus"
              className="mr-2 flex items-center justify-center mt-1 "
            />
            <span>Hide Advanced Filters</span>
          </div>
        </>
      )}
      <div className="flex justify-end my-4 space-x-3">
        <button
          className={`flex justify-center h-12 w-[139px] items-center bg-gray-300 text-black p-4 rounded-md hover:bg-indigo-600 transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-400 space-x-2 font-semibold text-sm`}
        >
          Reset
        </button>
        <button
          className={`flex justify-center h-12 w-[139px] items-center bg-indigo-500 text-white p-4 rounded-md hover:bg-indigo-600 transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-400 space-x-2 font-semibold text-sm`}
        >
          Apply
        </button>
      </div>
    </>
  );
}

export default GridFilter;
