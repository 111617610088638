import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data
import { useState } from "react";

// Data Grid Component
export const PDataGrid = () => {
  const [rowData, setRowData] = useState<any>([
    {
      playerId: "32453",
      userName: "John Doe",
      affiliateId: "12533",
      email: "a@a.com",
      verificationStatus: "Verified",
      balance: "1.200,00",
      winningTendency: "Low Tendency",
      lastLoginDate: "01/09/2021",
      lastLoginIpAddress: "1.1.1.1",
    },
    {
      playerId: "12224",
      userName: "Jane Doe",
      affiliateId: "24212",
      email: "b@b.com",
      verificationStatus: "Not Verified",
      balance: "2.200,00",
      winningTendency: "Low Tendency",
      lastLoginDate: "02/09/2021",
      lastLoginIpAddress: "2.2.2.2",
    },
    {
      playerId: "34522",
      userName: "John Smith",
      affiliateId: "12413",
      email: "c@c.com",
      verificationStatus: "Verified",
      balance: "3.200,00",
      winningTendency: "Low Tendency",
      lastLoginDate: "03/09/2021",
      lastLoginIpAddress: "3.3.3.3",
    },
  ]);

  const [colDefs, setColDefs] = useState<any>([
    {
      headerName: "Player ID",
      field: "playerId",
      filter: true,
      floatingFilter: true,
      cellClass: "ellipsis-cell",
      width: 150,
    },
    {
      headerName: "User Name",
      field: "userName",
      filter: true,
      floatingFilter: true,
      cellClass: "ellipsis-cell text-primary",
      cellStyle: { color: "primary" },
    },
    {
      headerName: "Affiliate Id",
      field: "affiliateId",
      filter: true,
      floatingFilter: true,
      cellClass: "ellipsis-cell",
      width: 150,
    },
    {
      headerName: "Email",
      field: "email",
      filter: true,
      floatingFilter: true,
      cellClass: "ellipsis-cell",
    },
    {
      headerName: "Verification Status",
      field: "verificationStatus",
      filter: true,
      floatingFilter: true,
      cellClass: "ellipsis-cell",
    },
    {
      headerName: "Balance",
      field: "balance",
      filter: true,
      floatingFilter: true,
      cellClass: "ellipsis-cell",
      cellStyle: { fontWeight: "bold" },
      valueFormatter: (params: any) => `€ ${params.value} `,
    },
    {
      headerName: "Winning Tendency",
      field: "winningTendency",
      filter: true,
      floatingFilter: true,
      cellClass: "ellipsis-cell",
      cellRenderer: (params: any) => (
        <span className="bg-success-light border border-success  text-success p-2 rounded-md">
          {params.value}
        </span>
      ),
    },
    {
      headerName: "Last Login Date",
      field: "lastLoginDate",
      filter: true,
      floatingFilter: true,
      cellClass: "ellipsis-cell",
    },
    {
      headerName: "Last Login IP Address",
      field: "lastLoginIpAddress",
      filter: true,
      floatingFilter: true,
      cellClass: "ellipsis-cell",
    },
  ]);

  return (
    <div className={"ag-theme-quartz h-full"}>
      <AgGridReact rowData={rowData} columnDefs={colDefs} />
    </div>
  );
};
