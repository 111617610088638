import React from "react";
import cx from "classnames";
import PlayersIcon from "@icons/players.svg";
import UsersOctaIcon from "@icons/user-octagon.svg";
import HouseIcon from "@icons/house.svg";
import ReportIcon from "@icons/report.svg";
import PaymentIcon from "@icons/payment.svg";
import PromotionIcon from "@icons/promotion.svg";
import BookOpenIcon from "@icons/book-open.svg";
import settingsIcon from "@icons/settings.svg";
import CategoryIcon2 from "@icons/category-2.svg";
import UserPenIcon from "@icons/user-pen.svg";
import RightArrow from "@icons/right-arrow.svg";
import calendar from "@icons/calendar.svg";
import Plus from "@icons/plus-sign.svg";
import Minus from "@icons/minus-sign.svg";

type IconProps = {
  iconName: string;
  className?: string;
  svgProps?: React.SVGProps<SVGSVGElement>;
  iconClass?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
};

type IconMap = {
  [key: string]: string;
};

const icons: IconMap = {
  players: PlayersIcon,
  calendar: calendar,
  usersOcta: UsersOctaIcon,
  house: HouseIcon,
  report: ReportIcon,
  plus: Plus,
  minus: Minus,
  payment: PaymentIcon,
  promotion: PromotionIcon,
  bookOpen: BookOpenIcon,
  settings: settingsIcon,
  categoryIcon2: CategoryIcon2,
  userPen: UserPenIcon,
  rightArrow: RightArrow,
};

export const Icon: React.FC<IconProps> = ({
  iconName,
  svgProps = { width: 24, height: 24 },
  disabled,
  className,
  ...props
}) => {
  const classNames = cx(className, {
    "pointer-events-none": disabled,
    "cursor-pointer": props?.onClick,
  });

  const SvgIcon = icons[iconName];
  if (!SvgIcon) return null;
  return (
    <div
      className={`flex flex-row justify-center items-center ${classNames} shrink-0`}
      data-testid={iconName}
      {...props}
    >
      <SvgIcon {...svgProps} aria-hidden="true" />
    </div>
  );
};

export default Icon;
