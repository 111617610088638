import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEn from "./langs/en.json";
import translationFr from "./langs/fr.json";
import { localStorageGetItem } from "../storage/storage-available";

// ----------------------------------------------------------------------

const lng = localStorageGetItem("i18nextLng", "en");


i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: translationEn },
      fr: { translations: translationFr }
    },
    lng,
    fallbackLng: "en",
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;