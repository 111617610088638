import React, { useState } from 'react';
import { useTranslate } from '../../utils/locales/use-locales';

const LanguageSelect = () => {
  
  let language = localStorage.getItem('i18nextLng');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(language?.toUpperCase() || 'EN');

  const { onChangeLang } = useTranslate()

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleLanguageChange = (language: any) => {
    setSelectedLanguage(language);
    setIsOpen(false);
    onChangeLang(language.toLowerCase());
    localStorage.setItem('i18nextLng', language.toLowerCase());
  };

  return (
    <div className="relative inline-block text-left h-full pt-3">
      <button
        className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        id="options-menu"
        aria-expanded={isOpen}
        aria-haspopup="true"
        onClick={toggleDropdown}
      >
        {selectedLanguage === 'EN' && <>🇺🇸 EN</>}
        {selectedLanguage === 'FR' && <>🇫🇷 FR</>}
        {selectedLanguage === 'DE' && <>🇩🇪 DE</>}
        {selectedLanguage === 'ES' && <>🇪🇸 ES</>}
        <svg
          className="-mr-1 ml-2 h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      {isOpen && (
        <div
          className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div className="py-1" role="none">
            <button
              onClick={() => handleLanguageChange('EN')}
              className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              role="menuitem"
            >
              🇺🇸 <span className="ml-3">EN</span>
            </button>
            <button
              onClick={() => handleLanguageChange('FR')}
              className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              role="menuitem"
            >
              🇫🇷 <span className="ml-3">FR</span>
            </button>
            {/* <button
              onClick={() => handleLanguageChange('DE')}
              className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              role="menuitem"
            >
              🇩🇪 <span className="ml-3">DE</span>
            </button>
            <button
              onClick={() => handleLanguageChange('Es')}
              className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              role="menuitem"
            >
              🇪🇸 <span className="ml-3">ES</span>
            </button> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageSelect;
