import {
  ArrowDownTrayIcon,
  ArrowsUpDownIcon,
  PauseIcon,
  UserPlusIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import PInput from "@components/core-components/input";
import BSelect from "@components/core-components/select"; // Assuming BSelect is the select component
import { useState } from "react";

interface QuickFiltersProp {
  closeFilters: boolean;
  setCloseFilters: (value: boolean) => void;
}

function QuickFilterHeader({
  setCloseFilters,
  closeFilters,
}: QuickFiltersProp) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const currencyOptions = [
    { value: "1", label: "USD" },
    { value: "2", label: "EUR" },
    { value: "3", label: "GBP" },
  ];
  const accountOptions = [
    { value: "2", label: "Current" },
    { value: "3", label: "Fixed Deposit" },
  ];
  const countryOptions = [
    { value: "1", label: "USA" },
    { value: "2", label: "UK" },
    { value: "3", label: "Germany" },
  ];
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  return (
    <>
      <div className="flex flex-col my-2 xxl:gap-6 xxl:flex-row justify-between">
        <div className="flex ">
          <button
            onClick={() => setCloseFilters(!closeFilters)}
            style={{ height: 45 }}
            className={`flex h-12 items-center bg-indigo-500 text-white px-4 py-2 rounded-md hover:bg-indigo-600 transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-400 space-x-2 font-semibold text-sm`}
          >
            <span>{!closeFilters ? "Close Filters" : "Open Filters"}</span>
            <ArrowsUpDownIcon className="h-5 w-5 text-white" />
          </button>
          <PInput
            placeholder="Search"
            value=""
            onChange={() => {}}
            className="ml-4 h-12 w-full md:w-80 sm:w-70 lg:w-96"
          />
        </div>

        <div className="flex space-x-6 m:flex-column">
          <button
            style={{ height: 45 }}
            className={`flex h-12 items-center bg-primary-light text-primary px-3 py-2 rounded-lg transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-400 space-x-2 font-semibold text-sm`}
          >
            <ArrowDownTrayIcon className="h-5 w-5 text-primary" />
            <span>Download CSV</span>
          </button>

          <button
            style={{ height: 45 }}
            className={`flex h-12 items-center bg-primary-light text-primary px-4 py-2 rounded-lg transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-400 space-x-2 font-semibold text-sm`}
          >
            <PauseIcon className="ml-2 h-5 w-5 text-primary" />
            <span>Edit Columns</span>
          </button>

          {/* Add User button */}
          <button
            onClick={() => setIsModalOpen(true)} // Open modal on click
            style={{ height: 45 }}
            className={`flex h-12 items-center bg-primary-light text-primary px-4 py-2 rounded-lg transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-400 space-x-2 font-semibold text-sm`}
          >
            <UserPlusIcon className="h-5 w-5 text-primary" />
            <span>Add User</span>
          </button>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg relative">
            {/* Close button */}
            <button
              onClick={() => setIsModalOpen(false)}
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
            >
              <XMarkIcon className="h-6 w-6" />
            </button>

            <h2 className="text-xl font-semibold mb-4">Add User</h2>

            <form>
              {/* Input fields */}
              <PInput placeholder="Username*" className="w-full h-12 " />
              <PInput placeholder="Email*" className="w-full h-12 " />
              <PInput placeholder="First Name*" className="w-full h-12 " />
              <PInput placeholder="Last Name*" className="w-full h-12 " />
              <PInput placeholder="Password*" className="w-full h-12 " />
              <PInput placeholder="Referral Number*" className="w-full h-12" />

              <BSelect
                options={accountOptions}
                value={selectedAccount}
                onChange={(e: any) => setSelectedAccount(e.target.value)}
                className="w-full"
              />
              <BSelect
                options={countryOptions}
                value={selectedCountry}
                onChange={(e: any) => setSelectedCountry(e.target.value)}
                className="w-full"
              />
              <BSelect
                options={currencyOptions}
                value={selectedCurrency}
                onChange={(e: any) => setSelectedCurrency(e.target.value)}
                className="w-full"
              />
              <div className="flex justify-between mt-4">
                <button
                  type="button"
                  onClick={() => setIsModalOpen(false)}
                  className="bg-gray-200 text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-300"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-indigo-500 text-white px-4 py-2 rounded-lg hover:bg-indigo-600"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default QuickFilterHeader;
