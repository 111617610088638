import DashboardLayout from "./components/layout-components/DashboardLayout";
import "./utils/locales/i18n";
import Router from "./pages/routes";
import Login from "pages/auth/login";

function App() {
  const isLogin = false;

  return <>{isLogin ? <Router /> : <Router />}</>;
}

export default App;
