import React from 'react'

function QuickAccess() {
    return <>
        <div style={{ borderRadius: 10 }} className='flex flex-col bg-gray-900 h-32 border-solid p-5 justify-between'>
            <div className='flex justify-between text-white '>
                <h3 className='text-lg font-semibold'>Player Quick Access</h3>
                <div className='space-x-6 underline'>
                    <a href=''>Edit Short</a>
                    <a href=''>Hide</a>
                </div>
            </div>
            <div className='flex text-white space-x-3'>
                <div style={{borderRadius:9}} className='bg-primary w-1/6 h-12 flex justify-center items-center cursor-pointer'>
                    Add Balance
                </div>
                <div style={{borderRadius:9, borderWidth:1}} className='w-1/6 h-12 border-dashed border-white flex justify-center items-center cursor-pointer'>
                    + Add Shortcut
                </div>
            </div>
        </div>
    </>
}

export default QuickAccess