import { Outlet } from 'react-router-dom';
import { AppRouteProps } from '../../components/router/AppRoute';
import List from './list';


export const playersRoutes: AppRouteProps[] = [
  {
    path: 'players',
    element: (
        <Outlet />

    ),
    children: [
      { path: 'list', element: <List />, role: "frmNurseProcessNew" },
    ],
  },
];





