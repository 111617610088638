import React, { ChangeEvent } from "react";
import { FieldError } from "react-hook-form";
import cx from "classnames";

interface PInputProps {
  id?: string;
  type?: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  className?: string;
  style?: React.CSSProperties;
  register?: any;
  error?: FieldError;
  label?: string;
}

const PInput: React.FC<PInputProps> = ({
  id,
  type = "text",
  placeholder = "",
  value,
  onChange,
  required = false,
  className = "",
  style,
  register,
  error,
  label,
}) => {
  const inputClassNames = cx(
    `${className} border text-body-reg-13 bg-gray-100 border-gray-400 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-400`,
    {
      "border-red-500": error,
    }
  );

  return (
    <div className={`flex flex-col mb-4`}>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <input
        style={style}
        type={type}
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={inputClassNames}
        required={required}
        {...(register && register(id))}
      />
      {error && (
        <p className="mt-1 text-red-500 text-body-reg-12">{error.message}</p>
      )}
    </div>
  );
};

export default PInput;
