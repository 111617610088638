import React, { useState } from "react";
import QuickAccess from "./components/QuickAccess";
import QuickFilterHeader from "./components/QuickFilterHeader";
import GridFilter from "./components/GridFilter";
import { PDataGrid } from "@components/layout-components/PDataGrid";

function List() {
  const [closeFilters, setCloseFilters] = useState(true);

  return (
    <>
      <QuickAccess />

      <h1 className="text-2xl font-bold mt-8 mb-8">Player Management</h1>
      <div className="bg-white flex flex-col h-full p-6">
        <QuickFilterHeader
          closeFilters={closeFilters}
          setCloseFilters={setCloseFilters}
        />

        <div className="flex flex-col flex-grow overflow-hidden">
          {" "}
          {!closeFilters && <GridFilter />}
          <div className="flex-grow h-screen overflow-auto">
            <PDataGrid />
          </div>
        </div>
      </div>
    </>
  );
}

export default List;
