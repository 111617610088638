import React, { ChangeEvent } from 'react';

interface BSelectOption {
    value: string;
    label: string;
}

interface BSelectProps {
    options: BSelectOption[];
    value: string;
    onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
    label?: string;
    required?: boolean;
    className?: string;
}

const BSelect: React.FC<BSelectProps> = ({
    options,
    value,
    onChange,
    required = false,
    className = "",
}) => {
    return (
        <div className={`flex flex-col mb-4 ${className}`}>
            <select
                value={value}
                onChange={onChange}
                className="border text-gray-700 text-sm h-11 w-full bg-gray-100 border-gray-400 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-400"
                required={required}
            >
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default BSelect;
