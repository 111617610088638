import { Navigate, Outlet } from "react-router-dom";
import { useAppRoutes } from "../hooks/useAppRoutes";
import DashboardLayout from "@components/layout-components/DashboardLayout";
import { playersRoutes } from "./players/routes";

export default function Router() {
  return useAppRoutes([
    {
      path: "/",
      element: (
        <DashboardLayout>
          <Outlet />
        </DashboardLayout>
      ),
      children: [...playersRoutes],
    },

    // No match 404
    { path: "*", element: <Navigate to="/" replace /> },
  ]);
}
